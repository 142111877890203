/*
 * TODO: on "fetchone", if item exists in loaded Collection, get from there, to avoid I/O
 * TODO: On update, fetch from disk, to get the "freshest" version.
 */

const now = () => {
  return new Date().toISOString();
};

const taskTemplate = {
  title: "",
  description: null,
  productivity: true,
  type: "task",
  status: "plan",
  priority: null,
  tags: [],
  due: null,
  start: now(),
  end: null,
  project: null,
  context: null,
  created: now()
};

/**
 * Requires a docid and a database object from database.js
 * If creating a new document, use "new" as docid argument or leave blank
 */
export default class Task {
  constructor(fileName, databaseObject) {
    this.id = fileName; // May not be needed
    this.db = databaseObject;
    const { doc, fileHandle, meta } = this.db.getOne("tasks", fileName);
    this.fileHandle = fileHandle;
    this.meta = meta;
    this.doc = { ...taskTemplate, ...doc };
  }

  get isClosed() {
    return ["done", "cancelled"].includes(this.doc.status);
  }

  cancel() {
    this.end("cancelled");
  }

  done() {
    this.end();
  }

  /**
   * Handles completing/finishing tasks. Used by cancel() and done()
   * @param {*} status - Task status. Defaults to "done"
   */
  end(status = "done") {
    const d = this.doc;
    d.status = status;
    d.end = now();
    this.insert();
  }

  reopen() {
    console.log("Reopening task. Work in progress")
    /**
     * TODO: set task plan, remove end date
     */
  }

  /**
   * Alias for insert()
   */
  save() {
    this.insert();
  }

  /**
   * Saves "this.doc" to disk via this.db.insert(docItem) in database.js.
   */
  insert() {
    this.db.insert({
      fileHandle: this.fileHandle,
      doc: this.doc
    });
  }

  archive() {
    console.log("work in progress")
    /**
     * TODO: this.doc.archived = true;
     * TODO: this.db.insert(this.docitem??)
     */
  }
}
