export { rankTask, rankTasks, isDueToday, isOverdue };

const priorityWeighting = {
  1: 3000,
  2: 1000,
  3: 400,
  4: 200,
  5: 50,
  6: -50,
  7: -100,
  8: -200,
  9: -300,
  10: -500
};

const statusWeighting = {
  doing: 100,
  next: 50,
  todo: 25,
  plan: 10,
  wait: -100,
  cancelled: -20000,
  done: -10000
};

/**
 *
 * @param {Date} d1
 * @param {Date} d2
 */
function isDueToday(task) {
  const d1 = new Date(task.due),
    d2 = new Date();
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

const isOverdue = task => {
  return new Date(task.due) < new Date();
};

function rankTask(task) {
  let extraFields = {
    score: 0,
    discoveries: []
  };
  let t = {
    ...task,
    ...extraFields
  };
  t.score += statusWeighting[t.status];

  if (t.description != null) {
    t.score += 5;
  }

  if (t.start) {
    let dateNow = new Date().getTime();
    let taskDate = new Date(t.start).getTime();
    t.score += Math.round((dateNow - taskDate) / (1000 * 60 * 60 * 24));
  }

  t.score += priorityWeighting[t.priority] || 0;
  if (t.priority === 1) t.discoveries.push("Important and urgent");
  if (t.dependency) t.discoveries.push("Task has a dependency");
  if (t.tags != null) t.score += 20;
  if (t.project != null) t.score += 20;

  if (t.due && isDueToday(t)) {
    t.score += 2000;
    t.discoveries.push("Due today");
  }

  if (isOverdue(t)) {
    t.score += 3000;
    t.discoveries.push("Overdue");
  }

  return t;
}

/**
 * Used for ranking/grading a tasks (or array of tasks) importance based on a set of criteria's
 * TODO: Add iterator functionality
 * @param {Object} payload - Can be a 'task document' {} or an array of 'tasks' [{},{}]
 */
async function rankTasks(payload) {
  if (Array.isArray(payload)) {
    return await Promise.all(
      payload.map(async row => {
        let task = row.doc || row;
        return rankTask(task);
      })
    );
  }
}
