<template>
  <input type="checkbox" v-model="doneToggle" />
  <del v-if="task.isClosed" v-text="task.doc.title" />
  <label v-else for="title" v-text="task.doc.title" /><br />
  <details>
    <summary>...more</summary>
    <blockquote>
      <fieldset>
        <legend>File attributes</legend>
        <dl>
          <div v-for="(key, value) in fileAttributes">
            <dt v-text="value" />
            <dd v-text="key" />
          </div>
        </dl>
      </fieldset>
      <button @click="testConsole">Console</button>
    </blockquote>
  </details>
</template>

<script>
import Task from "./task.js";

export default {
  name: "TaskItem",
  inject: ["db"],
  props: {
    fileName: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      task: new Task(this.fileName, this.db)
    };
  },
  computed: {
    doneToggle: {
      get() {
        return this.task.isClosed;
      },
      set(value) {
        if (value) {
          this.task.done();
        } else {
          this.task.reopen();
        }
      }
    },
    fileAttributes() {
      const { name, size, lastModifiedDate } = this.task.meta;
      return {
        Name: name,
        Size: size,
        "Last modified": lastModifiedDate
      };
    }
  },
  methods: {
    testConsole: function() {
      console.log(this.task);
    }
  }
};
</script>

<style scoped>
input[type="checkbox"] {
  margin-right: 0.4rem;
}

details {
  padding: 1rem;
}
</style>
