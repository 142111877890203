<template>
  <nav>
    <div>
      <button @click="db.loadCollection('tasks')">Reload</button>
    </div>
    <div>
      <input type="checkbox" v-model="includeCompleted" />
      <label>Include completed</label>
    </div>
    <div>
      <input v-model="searchInput" />
      <label>Search</label>
    </div>
  </nav>
  <main v-if="dbValid">
    <h2>Due</h2>
    <ul>
      <li
        v-for="entry in listDue"
        style="margin-bottom: 1rem;"
        :key="entry.fileHandle.name"
      >
        <TaskItem :fileName="entry.fileHandle.name" />
      </li>
    </ul>
    <h2>All</h2>
    <ul>
      <li
        v-for="entry in taskEntries"
        style="margin-bottom: 1rem;"
        :key="entry.fileHandle.name"
      >
        <TaskItem :fileName="entry.fileHandle.name" />
      </li>
    </ul>
  </main>
  <main v-else>
    breefkase directory not valid
  </main>
</template>

<script>
import { ref, inject, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import TaskItem from "@/components/TaskItem";
import { isDueToday, isOverdue } from "@/components/taskModules/taskRanker";

export default {
  name: "Tasks",

  setup() {
    const router = useRouter(),
      db = inject("db"),
      global = inject("global"),
      searchInput = ref(""),
      includeCompleted = ref(false);

    const dbValid = computed(() => {
      return db.breefkaseDirName;
    });

    const listDue = computed(() => {
      const entries = taskEntries;
      return entries.value.filter(
        entry => isOverdue(entry.doc) || isDueToday(entry.doc)
      );
    });

    const taskEntries = computed(() => {
      try {
        const entryList = db.collection.tasks.entries;
        const statusListClosed = ["done", "cancelled"],
          statusList = ["wait", "plan", "todo", "next", "doing"];
        if (includeCompleted.value) {
          statusList.push(...statusListClosed);
        }
        const xy = entryList.filter(entry => {
          if (!statusList.includes(entry.doc.status)) {
            return false;
          }
          if (searchInput.value.length > 1) {
            const z = JSON.stringify(entry.doc).includes(searchInput.value);
            if (!z) {
              return false;
            }
          }
          return true;
          /*
            }
          */
        });
        console.log(xy);
        return xy;
      } catch (e) {
        this.db.errorHandler({
          msg: "Tasks.vue reported an error under taskEntries(): ",
          error: e
        });
        return [
          {
            title: "Something didn't work as expected"
          }
        ];
      }
    });

    onMounted(() => {
      if (!dbValid.value) {
        router.push({ name: "Home" });
      }
    });

    return {
      dbValid,
      db,
      global,
      router,
      taskEntries,
      listDue,
      includeCompleted,
      searchInput
    };
  },
  components: {
    TaskItem
  }
};
</script>

<style scoped>
li {
  list-style-type: none;
  box-shadow: var(--card-shadow);
  border-bottom: 1px dotted gray;
  padding: 0.6rem;
}
</style>
